import React from "react";
import { StyledWrapper, StyledArticle, StyledBackground } from "./_styled/article-styled";

const Article = props => {
  console.log(props)
  return (
    <StyledWrapper bgImg={props.bgImg}>
        <StyledArticle>{props.children}</StyledArticle>
    </StyledWrapper>
  );
};

export default Article;

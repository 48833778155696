import styled from "styled-components";

const StyledWrapper = styled.div`
  margin-bottom: 40px;
  background-image: url(${props => props.bgImg});
  overflow: hidden;
  border-radius: 24px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 5px 2px #d0d0d0;
  &:hover {
    transform: translate(0px, -3px);
    box-shadow: 0px 0px 5px 4px #d0d0d0;
  }
`;

const StyledArticle = styled.article`
  padding: 16px;
  border-radius: 24px;
  background-image: url(${props => props.bgImg});
  background: #0000008f;
  color: white;
  height: 250px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export { StyledArticle, StyledWrapper };
